import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <section className="tp-about-area position-relative pt-120 pb-90 fix">
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-5 col-12">
              <div
                className="tp-about-img z-index wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                  Terms and conditions
                </h5>
              </div>
            </div>
            <div className="col-md-12">
              <h2 class="mb-2">Introduction</h2>
              <p>
                These Terms and Conditions (the "Agreement") govern your access
                and use of our online platform (collectively, the "Platform")
                through which consultations and counseling with qualified
                professionals may be provided in the areas of food and wellness.
                This includes the website{" "}
                <a href="https://www.prakruthie.com">www.prakruthie.com</a> and
                its related apps. By accessing or using the Platform, you agree
                to be bound by this Agreement. Please read this Agreement
                carefully before using the Platform. If you do not agree to any
                term of this Agreement, you must not access the Platform.
              </p>

              <h2 class="mb-2">Definitions</h2>
              <ul>
                <li>
                  <strong>Company:</strong> We, us, or our refers to any company
                  that owns and operates the Platform, including Prakruthie
                  Technologies.
                </li>
                <li>
                  <strong>Platform:</strong> The online platform owned and
                  operated by the Company that connects users with Medical
                  Practitioners and Counselors for consultations and counseling
                  services related to food and wellness.
                </li>
                <li>
                  <strong>
                    Medical Practitioners,Makeup Artists and Counselors:
                  </strong>{" "}
                  Licensed professionals who provide consultations and
                  counseling services through the Platform, including but not
                  limited to accredited, trained, and experienced general
                  practitioners, licensed psychologists, licensed marriage and
                  family therapists, licensed clinical social workers, and
                  licensed professional counselors. These professionals may hold
                  certifications relevant to food and wellness based on their
                  state and/or jurisdiction.
                </li>
                <li>
                  <strong>Third-Party Content:</strong> Content, products, or
                  services offered or provided by third parties that may be
                  displayed on the Platform, including links to other websites
                  or advertisements.
                </li>
              </ul>

              <h2 class="mb-2">Global Use and Compliance</h2>
              <p>
                The Platform is intended for a global audience. However, we
                acknowledge that data protection laws and regulations regarding
                AI use in the food and wellness sectors may vary by
                jurisdiction. We strive to comply with all applicable laws and
                regulations, including the General Data Protection Regulation
                (GDPR) and the California Consumer Privacy Act (CCPA). You are
                responsible for complying with any applicable laws and
                regulations in your jurisdiction when using the Platform.
              </p>

              <h2 class="mb-2">Medical Disclaimer</h2>
              <ul>
                <li>
                  The Platform is not a substitute for professional medical
                  advice, diagnosis, or treatment.
                </li>
                <li>
                  The information and services provided on the Platform are for
                  informational purposes only. You should always consult with
                  your doctor or other qualified healthcare professional before
                  making any decisions about your health or well-being.
                </li>
                <li>
                  The Platform does not offer emergency services. If you are
                  experiencing a medical emergency, please call your local
                  emergency number immediately.
                </li>
              </ul>

              <h2 class="mb-2">Use of AI Technology</h2>
              <p>
                The Platform may utilize various AI technologies to personalize
                your experience, connect you with appropriate professionals, and
                deliver relevant information and resources. We are committed to
                using AI responsibly and ethically in accordance with applicable
                laws and regulations.
              </p>

              <h2 class="mb-2">User Accounts and Responsibilities</h2>
              <ul>
                <li>
                  You must be 18 years of age or older to create an account and
                  use the Platform.
                </li>
                <li>
                  You are responsible for maintaining the confidentiality of
                  your account information, including your username and
                  password.
                </li>
                <li>
                  You agree to be responsible for all activity that occurs under
                  your account.
                </li>
                <li>
                  You agree not to use the Platform for any illegal or
                  unauthorized purpose.
                </li>
                <li>
                  You agree to comply with all applicable laws and regulations
                  when using the Platform.
                </li>
              </ul>

              <h2 class="mb-2">Collection and Use of Personal Information</h2>
              <p>
                We collect and use personal information to provide and improve
                the Platform, connect you with professionals, and personalize
                your experience. We comply with all applicable data protection
                laws and regulations regarding the collection, use, and storage
                of your personal information. You can find more information
                about our privacy practices in our{" "}
                <a href="https://www.prakruthie.com/privacy-policy">
                  Privacy Policy
                </a>
                .
              </p>

              <h2 class="mb-2">Third-Party Content</h2>
              <p>
                The Platform may contain Third-Party Content. We are not
                responsible for the accuracy, completeness, or reliability of
                any Third-Party Content. You should use your judgment and
                discretion when using any Third-Party Content.
              </p>

              <h2 class="mb-2">Intellectual Property</h2>
              <p>
                The content and materials on the Platform, including the
                trademarks, copyrights, and other intellectual property rights,
                are owned by the Company or its licensors. You may not use any
                content or materials on the Platform without the prior written
                consent of the Company.
              </p>

              <h2 class="mb-2">
                Disclaimer of Warranties and Limitation of Liability
              </h2>
              <p>
                The Platform is provided "as is" and without warranties of any
                kind, express or implied. The Company disclaims all warranties,
                including but not limited to warranties of merchantability,
                fitness for a particular purpose, and non-infringement. The
                Company will not be liable for any damages arising out of or
                related to your use of the Platform, including but not limited
                to direct, indirect, incidental, consequential, or punitive
                damages.
              </p>

              <h2 class="mb-2">Termination</h2>
              <p>
                The Company may terminate your access to the Platform at any
                time and for any reason, with or without notice. You may also
                terminate your account at any time.
              </p>

              <h2 class="mb-2">Governing Law and Dispute Resolution</h2>
              <p>
                This Agreement shall be governed by and construed in accordance
                with the laws of the Emirates of Abu Dhabi, excluding any rules
                governing choice of laws. Any dispute arising out of or relating
                to this Agreement shall be resolved by the courts of the
                Emirates of Abu Dhabi.
              </p>

              <h2 class="mb-2">Entire Agreement</h2>
              <p>
                This Agreement constitutes the entire agreement between you and
                the Company regarding your use of the Platform.
              </p>

              <h2 class="mb-2">Modifications</h2>
              <p>
                The Company may modify this Agreement at any time by posting the
                modified terms on the Platform. You are responsible for checking
                the terms of this Agreement periodically. Your continued use of
                the Platform after any modifications are posted constitutes your
                acceptance of the modifications.
              </p>

              <h2 class="mb-2">Assignment</h2>
              <p>
                The Company may assign or transfer this Agreement or any of its
                rights or obligations hereunder.
              </p>

              <h2 class="mb-2">Severability</h2>
              <p>
                If any provision of this Agreement is held to be invalid or
                unenforceable, such provision shall be struck and the remaining
                provisions shall remain in full force and effect.
              </p>

              <h2 class="mb-2">Notices</h2>
              <p>
                All notices required or permitted hereunder shall be in writing
                and shall be deemed to have been duly given when delivered
                personally, sent by certified or registered mail, postage
                prepaid, return receipt requested, or sent by email. Notices to
                the Company shall be sent to{" "}
                <a href="mailto:info@prakruthie.com">info@prakruthie.com</a>.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
