import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home/Home';
import About from './pages/About/About/About';
import Appointment from './pages/Appointment/Appointment/Appointment';
import Faq from './pages/Faq/Faq/Faq';
import Contact from './pages/Contact/Contact/Contact';
import { WOW } from 'wowjs';
import ScrollToTop from './components/ScrollToTop';
import Privacy from './pages/Privacy/Privacy';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';


const App = () => {
  // wow animation active
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;