import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="tp-about-area position-relative pt-120 pb-90 fix">
        {/* <div className="tp-about-shape">
               <img src="assets/img/about/about-shape-1.jpg" className="img-fluid" alt="img not found"/>
            </div> */}
        <div className="container">
          <div className="row justify-content-xl-between justify-content-md-center">
            <div className="col-xl-5 col-12">
              <div
                className="tp-about-img z-index wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20">
                  Privacy Policy
                </h5>
              </div>
            </div>
            <div className="col-md-12">
              <h2>Introduction</h2>
              <ul>
                <li>
                  <strong>Voluntary Feedback</strong>: We may request your
                  participation in surveys or market research to improve our
                  services, but this is entirely optional.
                </li>
                <li>
                  <strong>Third-Party Information</strong>: If we receive data
                  about you from third parties, it will be handled in accordance
                  with this policy.
                </li>
                <li>
                  <strong>Business Transfers</strong>: In the event of a merger,
                  acquisition, or sale, personal information may be transferred
                  to the new entity, subject to confidentiality agreements.
                </li>
              </ul>

              <h2>Control of Your Personal Information</h2>
              <ul>
                <li>
                  <strong>Direct Marketing Opt-Out</strong>: You can opt out of
                  direct marketing communications by emailing us at{" "}
                  <a href="mailto:info@prakruthie.com">info@prakruthie.com</a>.
                </li>
                <li>
                  <strong>Access and Correction</strong>: Request access to your
                  data or notify us of inaccuracies by contacting{" "}
                  <a href="mailto:info@prakruthie.com">info@prakruthie.com</a>.
                  We will respond within a reasonable timeframe.
                </li>
                <li>
                  <strong>Third-Party Restrictions</strong>: Your personal
                  information will not be sold, distributed, or leased to third
                  parties without your explicit consent.
                </li>
                <li>
                  <strong>Credit/Debit Card Information</strong>: Payment
                  details are not stored or shared with third parties. We use
                  secure platforms like Apple Pay and Google Pay for
                  transactions.
                </li>
              </ul>

              <h2>Complaints</h2>
              <p>
                If you believe we have violated data protection principles,
                please email{" "}
                <a href="mailto:info@prakruthie.com">info@prakruthie.com</a>. We
                will investigate your complaint promptly and respond with
                corrective actions and outcomes.
              </p>

              <h2>Storage and Security</h2>
              <ul>
                <li>
                  <strong>Encryption</strong>: Sensitive data is encrypted
                  during transmission and storage.
                </li>
                <li>
                  <strong>Physical and Digital Safeguards</strong>: Our systems
                  are secured against unauthorized access through advanced
                  technologies and strict protocols.
                </li>
                <li>
                  <strong>Data Breach Mitigation</strong>: In the unlikely event
                  of a data breach, we will notify affected users and take
                  remedial actions as required by law.
                </li>
                <li>
                  <strong>Limitations</strong>: While we take significant
                  precautions, we cannot guarantee absolute security of data
                  transmitted over the internet.
                </li>
              </ul>

              <h2>Cookies and Tracking Technologies</h2>
              <ul>
                <li>
                  <strong>Cookies</strong>: We use cookies to enhance user
                  experience by storing preferences and tracking usage patterns.
                  You can disable cookies in your browser settings.
                </li>
                <li>
                  <strong>Web Beacons</strong>: These tools help us analyze site
                  traffic and improve services.
                </li>
                <li>
                  <strong>Third-Party Analytics</strong>: We may use third-party
                  services for analytics, but data shared is anonymized.
                </li>
              </ul>

              <h2>Links to Other Sites</h2>
              <p>
                Our website may contain links to external sites. We are not
                responsible for the privacy practices or content of these sites.
                Please review their policies before providing personal
                information.
              </p>

              <h2>Information Disclaimer</h2>
              <p>
                Content on{" "}
                <a href="http://www.prakruthie.com">www.prakruthie.com</a> is
                for informational purposes only and is not a substitute for
                professional medical advice. Always consult a qualified
                healthcare provider for medical concerns.
              </p>

              <h2>Amendments to this Policy</h2>
              <p>
                This policy may be updated to reflect new requirements or
                standards. Changes take effect immediately upon posting. Please
                check this page frequently for updates.
              </p>

              <p>
                For inquiries, contact us at{" "}
                <a href="mailto:info@prakruthie.com">info@prakruthie.com</a>.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
