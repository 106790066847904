import React from 'react';
import { Link } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';
import { FaMapMarkerAlt, FaEnvelopeOpen,FaFacebookF } from 'react-icons/fa';
import { AiOutlineGoogle } from 'react-icons/ai';
import { BsTwitter, BsInstagram, BsYoutube } from 'react-icons/bs';

const Footer = () => {
   return (
      <>
         <footer className="theme-dark-bg">
            <div className="tp-footer-subscribe-area-two position-relative pt-100">
               <div className="container">
                  <div className="tp-footer-subscribe-bg-two theme-yellow-bg pt-30 pb-20 z-index pl-60 pr-60">
                     <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-4">
                           <div className="tp-footer-subscribe">
                              <h3 className="tp-footer-subscribe-title">Subscribe Our Newsletter</h3>
                           </div>
                        </div>
                        <div className="col-xl-7 col-lg-8">
                           <div className="tp-footer-subscribe-form">
                              <form action="#" className="p-0">
                                 <div className="tp-footer-subscribe-form-field mb-10">
                                    <input type="text" placeholder="Email Address"/>
                                       <i ><FaPaperPlane/></i>
                                 </div>
                                 <div className="tp-footer-subscribe-form-btn mb-10">
                                    <button type="submit" className="theme-btn text-white"><i className="flaticon-enter"></i> Subscribe</button>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tp-footer-area-two pt-80 pb-50">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-1 mb-30 wow fadeInUp" data-wow-delay=".3s">
                           <div className="tp-footer-info">
                              <div className="tp-footer-info-logo mb-35">
                                 <Link to="/"><img src="assets/img/logo/logo-white.png" className="img-fluid" alt="img not found"/></Link>
                              </div>
                              <h4 className="mb-15"><a href="tel:+91-6364317487">+91-6364317487</a></h4>
                              <h4 className="mb-15"><a href="tel:+91-6364777487">+91-6364317487</a></h4>
                              <h6 className="mb-15"> <i > <FaEnvelopeOpen/> </i><a href="mailto:info@prakruthie.com">info@prakruthie.com</a></h6>
                              <h6 className="mb-20"> <i> <FaMapMarkerAlt /> </i>2nd Floor Kamadhenu <br/>Arcade,Kenchena Halli Rd,<br/>Raja Rajeshwari Nagar, Bangalore,India-560098</h6>
                              <div className="tp-footer-info-social">
                                 <a href="https://www.facebook.com/profile.php?id=100095279033063"><i><FaFacebookF className='icon' /> </i></a>
                                 <a href="https://twitter.com/prakruthies"><i><BsTwitter className='icon' /> </i></a>
                                 <a href="https://www.instagram.com/prakruthieacademy/"><i><BsInstagram className='icon' /> </i></a>
                                 <a href="https://maps.app.goo.gl/YZCD8Vk6gHUci87h6"><i><AiOutlineGoogle className='icon' /> </i></a>
                                 <a href="https://www.youtube.com/channel/UC8Xgt3pKauYwpI1VR0I7soA"><i><BsYoutube className='icon' /> </i></a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp" data-wow-delay=".6s">
                           <h4 className="tp-footer-widget-title mb-35">Our Services</h4>
                           <ul>
                              <li><a href="#">Makeup Courses</a></li>
                              <li><a href="#">Hair Dressing Courses</a></li>
                              <li><a href="#">Mehandi Courses</a></li>
                              <li><a href="#">Nail Art Courses</a></li>
                              <li><a href="#">Saree Draping Courses</a></li>
                              <li><a href="#">Cosmetics Wholesale</a></li>
                              <li><a href="#">Cosmetics Retail</a></li>
                              <li><a href="#">Online Courses</a></li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-3 mb-30 wow fadeInUp" data-wow-delay=".9s">
                           <h4 className="tp-footer-widget-title mb-35">Recent News</h4>
                           <div className="tp-footer-news">
                              <div className="tp-footer-news-single mb-15">
                                 <h6><Link to="/blogDetails">Prakruthie has been selected by AOFM as best partner in India</Link></h6>
                                 <span>December 10,2023</span>
                              </div>
                              <hr style={{color:'white'}}/>
                              <div className="tp-footer-news-single">
                                 <h6><Link to="/blogDetails">Prakruthie tied up with Tira Lifestyle</Link></h6>
                                 <span>Jan 12, 2023</span>
                              </div>
                              <hr style={{color:'white'}}/>
                              <h4 className="tp-footer-widget-title mb-35">Other Links</h4>
                              <div className="tp-footer-news-single">
                                 <h6><Link to="/privacy-policy">Privacy Policy</Link></h6>
                              </div>
                              <div className="tp-footer-news-single">
                                 <h6><Link to="/terms-conditions">Terms and Conditions</Link></h6>
                              </div>
                              <hr style={{color:'white'}}/>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-4 mb-30 wow fadeInUp" data-wow-delay="1.2s">
                           <h4 className="tp-footer-widget-title mb-40">Instagram</h4>
                           <div className="tp-footer-insta">
                              <a href="https://www.instagram.com/reel/C4XlSB6LSCr/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">
                                 <img src="assets/img/footer/footer-insta-1.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="https://www.instagram.com/reel/C4XnF13NYiO/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">
                                 <img src="assets/img/footer/footer-insta-2.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="https://www.instagram.com/reel/C4QT8KkLO5R/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">
                                 <img src="assets/img/footer/footer-insta-3.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="https://www.instagram.com/p/C4QVrqCraHi/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">
                                 <img src="assets/img/footer/footer-insta-4.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="https://www.instagram.com/reel/C38ViwOBdqg/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">
                                 <img src="assets/img/footer/footer-insta-5.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                              <a href="https://www.instagram.com/reel/C4ZmDGkLL5s/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">
                                 <img src="assets/img/footer/footer-insta-6.jpg" className="img-fluid" alt="img not found"/>
                                 </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tp-copyright-area-two bg-green-light z-index pt-30 pb-30">
               <div className="container">
                  <div className="row">
                     <div className="col-12">
                        <div className="tp-copyright tp-copyright-two text-center">
                           <p className="m-0">Copyright ©2023 <span>Prakruthie</span>. All Rights Reserved Copyright</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;